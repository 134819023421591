import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { AuthenticationService, PushNotificationService, PreferencesService } from './_services';
import { Environment } from '@ionic-native/google-maps';
import { GOOGLE_MAPS_API_KEY } from '../environments/environment';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  public version = '';
  public isLoggedIn = false;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private preferencesSvc: PreferencesService,
    private appVersion: AppVersion,
    private pushNotificationSvc: PushNotificationService,
    private authSvc: AuthenticationService,
    private router: Router
  ) {
    this.initializeApp();
  }

  initializeApp() {
    console.log('Initialize app');
    this.platform.ready().then(() => {
      //this.statusBar.styleDefault();
      
      Environment.setEnv({
        'API_KEY_FOR_BROWSER_RELEASE': GOOGLE_MAPS_API_KEY,
        'API_KEY_FOR_BROWSER_DEBUG': GOOGLE_MAPS_API_KEY
      });

      this.splashScreen.hide();
    });
  }

  ngOnInit() {
    this.platform.ready().then(() => {


      this.authSvc.isLoggedIn.subscribe(state => {
        this.isLoggedIn =  state;
      });


      this.appVersion.getVersionNumber().then( ver => {
        this.version = ver;
        console.log(ver);
      });
    });    
  }

	logout() {
    this.authSvc.logout()
      .then(res => {
        this.router.navigateByUrl('/home', { replaceUrl: true }) 
      });
	}

}