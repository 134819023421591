import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment, API_SERVER_ADDRESS } from '../../environments/environment';
 
@Injectable({
  providedIn: 'root'
})
export class ApiService {
 
  /**
   * Constructor of the Service with Dependency Injection
   * @param http The standard Angular HttpClient to make requests
   */
  constructor(private http: HttpClient) { }
 



  getLines(): Observable<any> {
    return this.http.get(`${API_SERVER_ADDRESS}/lineas/index.json`);
  }
  getLine(linea_id, options): Observable<any> {
    if (!options) {
      options = {associated: 'ParadasLineas.Paradas;Expediciones.ParadaExpediciones.Paradas;Expediciones.FrecuenciasSemanales;Expediciones.GescarPlanningHoy'}
    };
    return this.http.get(`${API_SERVER_ADDRESS}/lineas/view/${linea_id}.json`, {params: options});
  }

  getExpedition(expedicion_id, options): Observable<any> {
    if (!options) {
      options = {};
    }
    return this.http.get(`${API_SERVER_ADDRESS}/expediciones/view/${expedicion_id}.json`, {params: options}).pipe(
      map(results => results)
    );
  }

  getLineasByStop(parada_id): Observable<any> {
    return this.http.get(`${API_SERVER_ADDRESS}/paradas/${parada_id}/lineas.json`).pipe(
      map(results => results)
    );
  }

  getStops(options): Observable<any> {
    if (!options) {
      options = {};
    }  
    return this.http.get(`${API_SERVER_ADDRESS}/paradas/index.json`,  {params: options}).pipe(
      map(results => results)
    );
  }

  getStop(parada_id): Observable<any> {
    return this.http.get(`${API_SERVER_ADDRESS}/paradas/view/${parada_id}.json`).pipe(
      map(results => results)
    );
  }



  getComunicaciones(): Observable<any> {
    return this.http.get(`${API_SERVER_ADDRESS}/comunicaciones/index.json`);
  }
  getComunicacion(aviso_id): Observable<any> {
    return this.http.get(`${API_SERVER_ADDRESS}/comunicaciones/view/${aviso_id}.json`);
  }


  postReserva(reserva): Observable<any> {
    return this.http.post(`${API_SERVER_ADDRESS}/reservas/add.json`, reserva);
  }


  getReservas(): Observable<any> {
    return this.http.get(`${API_SERVER_ADDRESS}/reservas/index.json`);
  }



}
