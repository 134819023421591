import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';


@Injectable()

export class ErrorInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private toastController: ToastController, 
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                console.log(err);
            }

            // const error = err.error.message || err.statusText;
            // return throwError(error);
            return throwError(err);
        }))
    }
}