import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage-angular';
import { BehaviorSubject } from 'rxjs';

 
@Injectable({
	providedIn: 'root'
})
export class PreferencesService {

	private _storage: Storage | null = null;
	canales_pausados = new BehaviorSubject([]);
	paradas_favoritas = new BehaviorSubject([]);
	busquedas_favoritas = new BehaviorSubject([]);
	busquedas_recientes = new BehaviorSubject([]);

	constructor(
        private storage: Storage,   
		private plt: Platform
	)  { 
		this.plt.ready().then(() => {
			this.init();
		});
	}
	

	async init() {
		console.log('Init Preferences Service');

		const storage = await this.storage.create();
		this._storage = storage;
		const canales = await this.storage.get('canales');
		if (canales) {
			//console.log('Canales on init', canales);
			this.canales_pausados.next(canales);
		}
		else {
			this.canales_pausados.next([]);
		}
		const paradas = await this.storage.get('paradas');
		if (paradas) {
			this.paradas_favoritas.next(paradas);
		}
		else {
			this.paradas_favoritas.next([]);
		}
		const busquedas = await this.storage.get('busquedas');
		if (busquedas) {
			this.busquedas_favoritas.next(busquedas);
		}
		else {
			this.busquedas_favoritas.next([]);
		}
		const busquedas2 = await this.storage.get('busquedas_recientes');
		if (busquedas2) {
			this.busquedas_recientes.next(busquedas2);
		}
		else {
			this.busquedas_recientes.next([]);
		}
	}


	getCanales = function() {
        return this.storage.get('canales');
	}
	setCanal = function(canal) {
		let canales = this.canales_pausados.value;
		canales.push(canal);
		this.canales_pausados.next(canales);
		this._storage?.set('canales', canales);
	}
	delCanal = function(canal) {
		let canales = this.canales_pausados.value;
		const index = canales.indexOf(canal);
		if (index > -1) {
			canales.splice(index, 1);
		}		
		this.canales_pausados.next(canales);
		this._storage?.set('canales', canales);
	}


	getParadas = function() {
        return this.storage.get('paradas');
	}
	saveParada = function(parada) {
		let paradas = this.paradas_favoritas.value;
		const index = paradas.findIndex(item => item.parada == parada.parada);
		if (index == -1) {
			paradas.push(parada);
		}		
		this.paradas_favoritas.next(paradas);
		this._storage?.set('paradas', paradas);  	
	}
    deleteParada = function(parada) {
		let paradas = this.paradas_favoritas.value;
		const index = paradas.findIndex(item => item.parada == parada.parada);
		if (index > -1) {
			paradas.splice(index, 1);
		}		
		this.paradas_favoritas.next(paradas);
		this._storage?.set('paradas', paradas);   	
    }


	getBusquedas = function() {
        return this.storage.get('busquedas');
	}
	saveBusqueda = function(busqueda) {
		let busquedas = this.busquedas_favoritas.value;
		const index = busquedas.findIndex(item => {
			return (
				(item.origen_id == busqueda.origen_id)
				&& (item.destino_id == busqueda.destino_id)
				&& (item.selector == busqueda.selector)
			);
		});
		if (index == -1) { //Solo añadimos si no está repetido
			busquedas.push(busqueda);
		}
		this.busquedas_favoritas.next(busquedas);
		this._storage?.set('busquedas', busquedas);  	
		
	}
    deleteBusqueda = function(busqueda) {
		let busquedas = this.busquedas_favoritas.value;
		const index = busquedas.findIndex(item => {
			return (
				(item.origen_id == busqueda.origen_id)
				&& (item.destino_id == busqueda.destino_id)
				&& (item.selector == busqueda.selector)
			);
		});
		if (index > -1) {
			busquedas.splice(index, 1);
		}
		console.log(busquedas);
		this.busquedas_favoritas.next(busquedas);
		this._storage?.set('busquedas', busquedas);   	
    }
	checkBusqueda = function(busqueda) {
		let busquedas = this.busquedas_favoritas.value;
		const index = busquedas.findIndex(item => {
			return (
				(item.origen_id == busqueda.origen_id)
				&& (item.destino_id == busqueda.destino_id)
				&& (item.selector == busqueda.selector)
			);
		});
		return (index!=-1);
	}


	getBusquedasRecientes = function() {
        return this.storage.get('busquedas_recientes');
	}
	saveBusquedaReciente = function(busqueda) {
		let busquedas_recientes = this.busquedas_recientes.value;
		const index = busquedas_recientes.findIndex(item => {
			return (
				(item.origen_id == busqueda.origen_id)
				&& (item.destino_id == busqueda.destino_id)
				&& (item.selector == busqueda.selector)
			);
		});
		if (index == -1) { //Solo añadimos si no está repetido
			busquedas_recientes.unshift(busqueda);
		}
		if (busquedas_recientes.length > 5) {
			console.log('mas de 5');
			let p = busquedas_recientes.slice(0,5);
			busquedas_recientes = p;
		}
		this.busquedas_recientes.next(busquedas_recientes);
		this._storage?.set('busquedas_recientes', busquedas_recientes);
	}	
}