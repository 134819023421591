import { FormControl, FormGroup } from '@angular/forms';

export class PasswordValidator {

	static areEqual(formGroup: FormGroup) {

 		let pass = formGroup.controls.password.value;
  		let confirmPass = formGroup.controls.password_confirm.value;		

		return pass === confirmPass ? null : { areEqual: true };

	}
}