import { Injectable } from '@angular/core';
import { IonButtons, ToastController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
  
export class ToastService {

  constructor(private toastCtrl: ToastController) { }

  
  async showToast(color: string, header: string, msg: string) {

    const toast = await this.toastCtrl.create({
      header: header,
      message: msg,
      duration: 7000,
      color: color,
      buttons: [
        {
          side: 'start',
          icon: 'mail-outline',
          text: ''
        }
      ],
      position: 'top'
    });
    toast.present();
  }
}