 import { AbstractControl } from '@angular/forms';

 export class SpanishIdValidator {
    
	static isValid(control: AbstractControl) {

        var value = control.value.toUpperCase();

        const DNI_REGEX = /^(\d{8})([A-Z])$/;
        const NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;
        const dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";

        if ( value.match( DNI_REGEX ) ) {
            let letter = dni_letters.charAt( parseInt( value, 10 ) % 23 );
            return (letter == value.charAt(8) ? null : { isValid: true });
        }

        if ( value.match( NIE_REGEX ) ) {
            // Change the initial letter for the corresponding number and validate as DNI
            let nie_prefix = value.charAt( 0 );
            switch (nie_prefix) {
                case 'X': nie_prefix = 0; break;
                case 'Y': nie_prefix = 1; break;
                case 'Z': nie_prefix = 2; break;
            }       
            //return this._validDNI( nie_prefix + nie.substr(1) );
            let value_nie = nie_prefix + value.substr(1);
            let letter = dni_letters.charAt( parseInt( value_nie, 10 ) % 23 );
            return (letter == value_nie.charAt(8) ? null : { isValid: true });
        }
    }
 }
