import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Platform } from '@ionic/angular';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';

import { Router } from '@angular/router';

import { ToastService } from './toast.service';
import { PreferencesService } from './preferences.service';



@Injectable({
    providedIn: 'root'
})
export class PushNotificationService {

  canales_pausados = [];
	notifications = new BehaviorSubject(null);

  constructor(
    private plt: Platform,
    private router: Router,
    private firebaseX: FirebaseX,
    private toastService: ToastService, 	
	  private preferencesSvc: PreferencesService, 
  ) {
   this.init();
  }


  async init() {
    this.plt.ready().then(() => {

      console.log('Init Push Service');

      if (this.plt.is('ios')) {
        this.firebaseX.grantPermission()
          .then(hasPermission => {
            console.log("Permission was " + (hasPermission ? "granted" : "denied"));
            this.firebaseX.getAPNSToken()
              .then(token => {
                console.log(`The apn token (push init) is ${token}`)
              })
              .catch(error => {
                console.error('Error getting apn token (push init)', error);
              });
          });
      }

      if (this.plt.is('ios')) {
        this.firebaseX.onApnsTokenReceived()
          .subscribe((token: string)=> {
            console.log(`Got a new firebase token  (PushNotification Service Listener) ${token}`)
          }, 
          error => {
            console.log(error);
          });
      }


      this.preferencesSvc.canales_pausados.subscribe(
        canales => {this.canales_pausados = canales; this.subscribeToChannels();},
        err => console.log(err)
      ); 



      this.firebaseX.onMessageReceived()
        .subscribe(
          notification => {
            console.log('Notification - onMessageReceived', notification);
            this.notifications.next(notification);
            const payload = JSON.parse(notification.payload);

            //Si hubiera más canales habría que determinar como actuar ante cada uno

            if (notification.tap == 'background') {
              console.log('Received in background');
              if (payload.data.hasOwnProperty('Comunicacion')) {
                this.router.navigate(['/tabs/comunicaciones/detalle', payload.data.Comunicacion.id]);
              }
            }
            else {
              console.log('Received in foreground');
              this.toastService.showToast('secondary', payload.title, payload.message);              
            }
          }
        );
    });
  }

  subscribeToChannels() {
    if (this.canales_pausados.findIndex(item => 'comunicaciones' == item) == -1) {
      this.firebaseX.subscribe('comunicaciones')
        .then(res => {
            console.log('Subscribed to topic comunicaciones');
        })
        .catch(err => {
            console.error('Error subscribing to topic comunicaciones : ' + err);
        });
    }
    else {
      this.firebaseX.unsubscribe('comunicaciones')
        .then(res => {
            console.log('Unsubscribed to topic comunicaciones');
        })
        .catch(err => {
            console.error('Error unsubscribing to topic comunicaciones : ' + err);
        });      
    }
  }


}
