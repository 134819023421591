import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { HttpClient } from  '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap } from  'rxjs/operators';
import { UserService } from './user.service';
import { environment, AUTH_SERVER_ADDRESS } from '../../environments/environment';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {
 
	isLoggedIn = new BehaviorSubject(null);
	private _storage: Storage | null = null;

	constructor(
		private httpClient:  HttpClient, 
		private userService: UserService, 
		private storage: Storage, 
		private plt: Platform,
		private firebaseX: FirebaseX,
	) { 
		this.plt.ready().then(() => {
			this.init();
		});
	}

	async init() {
		console.log('Init Auth Service');
		// If using, define drivers here: await this.storage.defineDriver(/*...*/);
		const storage = await this.storage.create();
		this._storage = storage;
		const token = await storage.get('TOKEN_KEY');
		if (token) {		
			this.isLoggedIn.next(token);
			this.userService.syncUserActivo().subscribe();

			if (this.plt.is('ios')) {
				this.firebaseX.grantPermission()
					.then(hasPermission => {
						console.log("Permission was " + (hasPermission ? "granted" : "denied"));
						this.firebaseX.getAPNSToken()
							.then(token => {
								console.log(`The apn token (auth init) is ${token}`)
							})
							.catch(error => {
								console.error('Error getting apn token', error);
							});
					});
			
			}
			this.firebaseX.getToken()
				.then(token => {
					console.log(`The firebase token (auth init) is ${token}`)
					this.userService.updateFirebaseToken({firebase_token: token});
				})
				.catch(error => {
					console.error('Error getting token', error);
				});
		}
		else {
			this.isLoggedIn.next(null);
			await storage.clear();
		}
	}



	login(credentials) {
		return this.httpClient.post(`${AUTH_SERVER_ADDRESS}/login.json`, credentials).pipe(
			tap(async (res) => {
				if (res) {
					this.isLoggedIn.next(res['token']);
					await this.storage.set('TOKEN_KEY', res['token']);
					this.userService.setUserActivo(res['usuario']);
					if (this.plt.is('ios')) {
						this.firebaseX.grantPermission()
							.then(hasPermission => {
								console.log("Permission was " + (hasPermission ? "granted" : "denied"));
								this.firebaseX.getAPNSToken()
									.then(token => {
										console.log(`The apn token (auth init) is ${token}`)
									})
									.catch(error => {
										console.error('Error getting apn token', error);
									});
							});
						}
					this.firebaseX.getToken()
						.then(token => {
							console.log(`The firebase token (login) is ${token}`)
							this.userService.updateFirebaseToken({firebase_token: token});
						})
						.catch(error => {
							console.error('Error getting token', error);
						});
				}
			})
		);
	} 

	logout() {
		return this.storage.remove('TOKEN_KEY').then(() => {
			this.isLoggedIn.next(null);
			this.userService.delUserActivo();
		});
	}

	async getToken() {
		const token =  await this.storage.get('TOKEN_KEY');
		return token;
	}	
 
	registro(credentials) {
		credentials.username = credentials.email;
		credentials.source = 'app';
		if (!credentials.marketing) {
			delete credentials.marketing;
		}
		return this.httpClient.post(`${AUTH_SERVER_ADDRESS}/usuarios/registro.json`, credentials).pipe(
			tap(async (res) => {
				if (res) {
					this.isLoggedIn.next(res['token']);
					await this.storage.set('TOKEN_KEY', res['token']);
					this.userService.setUserActivo(res['usuario']);
				}
			})
		);
	}

	recuperar(email) {
		console.log(email);
		return this.httpClient.post(`${AUTH_SERVER_ADDRESS}/usuarios/requestResetPassword.json`,  {reference: email}).pipe(
			tap(async (res) => {
	
			})
		);
	}





}