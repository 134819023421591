import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_guards';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/login/login.module').then( m => m.LoginPageModule)
  },
  { 
    path: 'registro', 
    loadChildren: () => import('./auth/registro/registro.module').then( m => m.RegistroPageModule)
  },  
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'informacion',
    loadChildren: () => import('./informacion/informacion.module').then( m => m.InformacionPageModule)
  },
  {
    path: 'lineas',
    loadChildren: () => import('./informacion/lineas/lineas.module').then( m => m.LineasPageModule)
  },
  {
    path: 'comunicaciones',
    loadChildren: () => import('./comunicaciones/comunicaciones.module').then( m => m.ComunicacionesPageModule)
  },
  {
    path: 'mapa',
    loadChildren: () => import('./mapa/mapa.module').then( m => m.MapaPageModule)
  },
  {
    path: 'paradas',
    loadChildren: () => import('./preferencias/paradas/paradas.module').then( m => m.ParadasPageModule)
  },
  {
    path: 'preferencias',
    loadChildren: () => import('./preferencias/preferencias.module').then( m => m.PreferenciasPageModule)
  },
  {
    path: 'otros',
    loadChildren: () => import('./otros/otros.module').then( m => m.OtrosPageModule)
  },
  {
    path: 'reservas',
    loadChildren: () => import('./reservas/reservas.module').then( m => m.ReservasPageModule)
  },
  {
    path: 'recuperar-contrasena',
    loadChildren: () => import('./auth/recuperar-contrasena/recuperar-contrasena.module').then( m => m.RecuperarContrasenaPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
