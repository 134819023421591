import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { ApiService } from './api.service';
import { tap } from  'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { environment, API_SERVER_ADDRESS } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

 
@Injectable({
	providedIn: 'root'
})
export class UserService {
 
	currentUser = new BehaviorSubject(null);

	constructor(
		private apiService: ApiService, 
		private storage: Storage, 
		private http: HttpClient
	)  { 

	}
	syncUserActivo = function() {
		console.log('Sync user');
		return this.apiService.getUserActive().pipe(
			tap(async (response: any) => {
				if (response) {
					this.setUserActivo(response.usuario);
				}
			})
		);
	}		
	getUserActive = function() {
		return this.storage.get('usuario').then(user => {
			if (user) {
				this.currentUser.next(user);
				return user;
			}
		})
	}	
	updateFirebaseToken = function(token) {
		console.log('About to update firebase');
		return this.http.post(`${API_SERVER_ADDRESS}/usuarios/updateFirebaseToken.json`, token).pipe(
			tap(
				async (response: any) => {
					console.log('--------------------Firebase token updated', response.user);
					this.setUserActivo(response.usuario);
				},
				err => {
					console.log('----------------------', err);
				}
			)
		).subscribe();
	}		
	setUserActivo = function(user) {
		this.storage.set('usuario', user);
		this.currentUser.next(user);
	}	
	delUserActivo = function() {
		this.storage.remove('usuario');
		this.currentUser.next(null);
	}	
	updatePassword = function(user) {
		return this.http.post(`${API_SERVER_ADDRESS}/usuarios/update.json`, user);
	}	

}