import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Platform } from '@ionic/angular';


@Injectable()

export class VersionInterceptor implements HttpInterceptor {

    version = '';

    constructor(
        private appVersion: AppVersion,
        private platform: Platform
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // if (this.platform.is('android') || this.platform.is('iphone')) {
        //     this.appVersion.getVersionNumber().then(ver => {
        //         this.version = ver;
        //     });
        // }

        // request = request.clone({
        //     setHeaders: {
        //         'App-version': this.version
        //     }
        // });
        return next.handle(request);
    }
}
