import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Expedicion } from '../_models';
import { ApiService } from '../_services/api.service';

 
@Injectable({
	providedIn: 'root'
})
export class ExpedicionesService {

	constructor(
		private apiSvc: ApiService, 
    )  { 
	}
	


}